<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Form Service
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()"  >
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Nama 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlnama" v-model.trim="formData.nama"  label="Nama" type="text" placeholder="Nama"    readonly  
                                                    class=" w-full" :class="getErrorClass('nama')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('nama')" class="p-error">{{ getFieldError('nama') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrlalamat"  ref="ctrlalamat" v-model="formData.alamat" type="hidden" />
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    No WA 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlno_wa" v-model.trim="formData.no_wa"  label="No WA" type="text" placeholder="No WA"    readonly  
                                                    class=" w-full" :class="getErrorClass('no_wa')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('no_wa')" class="p-error">{{ getFieldError('no_wa') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Merek 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlmerek" v-model.trim="formData.merek"  label="Merek" type="text" placeholder="Merek"      
                                                    class=" w-full" :class="getErrorClass('merek')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('merek')" class="p-error">{{ getFieldError('merek') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Tipe Perangkat 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Dropdown  class="w-full" :class="getErrorClass('tipe_perangkat')"      optionLabel="label" optionValue="value" ref="ctrltipe_perangkat"  v-model="formData.tipe_perangkat" :options="app.menus.tipePerangkatItems" label="Tipe Perangkat"  placeholder="Tipe Perangkat" >
                                                    </Dropdown> 
                                                    <small v-if="isFieldValid('tipe_perangkat')" class="p-error">{{ getFieldError('tipe_perangkat') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Serial Number 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlserial_number" v-model.trim="formData.serial_number"  label="Serial Number" type="text" placeholder="Serial Number"      
                                                    class=" w-full" :class="getErrorClass('serial_number')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('serial_number')" class="p-error">{{ getFieldError('serial_number') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Foto Barang 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <div class="mb-3">
                                                        <Uploader :class="getErrorClass('foto_barang')" upload-path="fileuploader/upload/foto_barang" v-model="formData.foto_barang" :fileLimit="1" :maxFileSize="25000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" label="Choose files or drop files here" />
                                                    </div>
                                                    <small v-if="isFieldValid('foto_barang')" class="p-error">{{ getFieldError('foto_barang') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Tanggal Booking 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Calendar  :showButtonBar="true" class="w-full" :class="getErrorClass('tanggal_booking')" dateFormat="yy-mm-dd" v-model="formData.tanggal_booking" :showIcon="true"     mask="YYYY-MM-DD"      />
                                                    <small v-if="isFieldValid('tanggal_booking')" class="p-error">{{ getFieldError('tanggal_booking') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrluser_id"  ref="ctrluser_id" v-model="formData.user_id" type="hidden" />
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Booking" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </main>
</template>
<script setup>
	    import {  computed,  reactive, toRefs, onMounted } from 'vue';
    import { required } from 'src/services/validators';
    import { useApp } from 'src/composables/app.js';
    import { useAuth } from 'src/composables/auth';
    import { useAddPage } from 'src/composables/addpage.js';
    import { usePageStore } from 'src/store/page';
    import axios from 'axios'; // import axios
    //[--PAGE-IMPORT-STATEMENT--]
    const props = defineProps({
        pageStoreKey: {
            type: String,
            default: 'FORM_SERVICE',
        },
        pageName : {
            type : String,
            default : 'form_service',
        },
        routeName : {
            type : String,
            default : 'form_serviceadd',
        },
        apiPath : {
            type : String,
            default : 'form_service/add',
        },
        submitButtonLabel: {
            type: String,
            default: "Booking",
        },
        formValidationError: {
            type: String,
            default: "Form is invalid",
        },
        formValidationMsg: {
            type: String,
            default: "Please complete the form",
        },
        msgTitle: {
            type: String,
            default: "Create Record",
        },
        msgAfterSave: {
            type: String,
            default: "Data berhasil ditambahkan",
        },
        msgBeforeSave: {
            type: String,
            default: "",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
        redirect: {
            type : Boolean,
            default : true,
        },
        isSubPage: {
            type : Boolean,
            default : false,
        },
        pageData: { // use to set formData default values from another page
            type: Object,
            default: () => {} 
        },
    });
    //lines
    const store = usePageStore(props.pageStoreKey);
    const app = useApp();
    const auth = useAuth();
    const formDefaultValues = {
        nama: auth.user.nama, 
        alamat: auth.userId, 
        no_wa: auth.user.no_wa, 
        merek: "", 
        tipe_perangkat: "", 
        serial_number: "", 
        foto_barang: "", 
        tanggal_booking: new Date(), 
        user_id: auth.userId, 
    };
    const formData = reactive({ ...formDefaultValues });
    //vuelidate form validation rules
    const rules = computed(() => {
        return {
            nama: {  },
            alamat: {  },
            no_wa: {  },
            merek: {  },
            tipe_perangkat: {  },
            serial_number: {  },
            foto_barang: {  },
            tanggal_booking: {  },
            user_id: {  }
        }
    });
    const page = useAddPage({ store, props, formData, rules, beforeSubmit, afterSubmit });
    //event raised before form submit
    async function beforeSubmit(){ // make the function async
    try { // use try...catch
        var data = JSON.stringify({
            "chat_id": "-4236195207", // replace with your chat id
            "text": formData.nama+",\nBaru saja melakukan Booking Servcie untuk Tanggal "+formData.tanggal_booking+" dengan detail sebagai berikut:\n\n"+"Merek Perangkat: "+formData.merek+"\nTipe Perangkat: "+formData.tipe_perangkat+"\nSerial Number: "+formData.serial_number+"\nNo. WhatsApp: https://wa.me/"+formData.no_wa+"\n\nTerima Kasih."
            // replace with your text
        });
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.telegram.org/bot7401791559:AAEHlrF7ofsgxcL7kEpLW6_R8e5KV-B549c/sendMessage', // replace with your bot url
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        const response = await axios(config); // use await to get the response
        console.log(JSON.stringify(response.data)); // print the response data
    }
    catch (error) { // catch the error
        console.log(error); // print the error
    }
    return true;
}
    // event raised after form submited
    function afterSubmit(response) { 
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        page.setFormDefaultValues(); //reset form data
        if(app.isDialogOpen()){
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if(props.redirect) {
            app.navigateTo(`/form_service/list_user`);
        }
    }
    const {  saving, pageReady, } = toRefs(page.state);
    const { submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
    onMounted(()=>{
        const pageTitle = "Form Service";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
    });
    // expose page object for other components access
    defineExpose({
        page
    });
</script>
<style scoped>
</style>
<style scoped>

</style>
